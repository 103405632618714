import * as Sentry from "@sentry/react";
import requestConfig from '../../shared/config/request';
import APIError, { APIErrorData } from '../../shared/errors/api-error';
import NetworkError from '../../shared/errors/network-error';
import captureError from '../../shared/utils/capture-error';
import { getFirebaseToken, getFirebaseTokenFromPlatform } from '../auth';

export enum Methods {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export enum RequestURLType {
  CRM_MINI = 'CRM_MINI',
  BOT_BUILDER = 'BOT_BUILDER',
}

async function parseResponseToJson<T>(response: Response) {
  try {
    const result = (await response.json()) as T;
    return result;
  } catch (parseToJsonError) {
    throw new Error('Failed to parse json response');
  }
}

export async function makeRequest<T>(
  url: string,
  options?: RequestInit & { useDefaultHeaders?: boolean },
  isAuthRequired: boolean = false,
  requestUrlType: RequestURLType = RequestURLType.CRM_MINI,
  retry: boolean = true,
  hasFullUrl?: boolean,
  ignoreSentry?: boolean
): Promise<T> {
  // init the token as a string or undefined
  let token: string | undefined;
  const queryParams = new URLSearchParams(window.location.search);
  const parentOrigin = queryParams.get('parent-origin') || window.localStorage.getItem('parent-origin');

  // if the flag isAuthRequired is true
  if (isAuthRequired) {
    // get the auth token from firebase and assign the same to token
    token = await getFirebaseToken();
  }
  const {
    method = Methods.GET,
    body,
    headers,
    useDefaultHeaders = true,
  } = options ?? {};
  const fullUrl: string = hasFullUrl
    ? url
    : `${
        requestUrlType === RequestURLType.BOT_BUILDER
          ? requestConfig.botBuilderBaseUrl
          : requestConfig.baseUrl
      }${url}`;
  let response: Response;
  try {
    response = await fetch(`${fullUrl}`, {
      ...options,
      method,
      body,
      headers: {
        'custom-origin': decodeURIComponent(parentOrigin ?? ''),
        ...(useDefaultHeaders ? defaultHeaders : {}),
        ...headers,
        ...(token && { Authorization: token }),
      },
    });
    if (!response.ok) {
      const errorMessage = `Request to ${url} failed with status ${response.status}`;
      Sentry.captureException(new Error(errorMessage), {
        extra: {
          url,
          options,
          status: response.status,
        },
      });
    }
  } catch (requestError) {
    captureError(requestError as Error);
    Sentry.captureException(requestError, {
      extra: {
        url,
        method: method || 'GET',
        headers: headers,
        body: body,
        message: (requestError as Error)?.message,
      },
    });
    throw new NetworkError(requestError as Error);
  }

  if (response.status === 401 && retry) {
    await getFirebaseTokenFromPlatform();
    return makeRequest(url, options, isAuthRequired, requestUrlType, false);
  }

  if (!response.ok) {
    const errorResult = await parseResponseToJson<APIErrorData>(response);
    const error = new APIError(errorResult);
    const ignorableErrors = [
      'failed to fetch',
      'too many requests',
      'rate limit exceeded',
      'forbidden resource',
      'code expired or not found',
      'whatsapp integration is not accessible',
      'user does not belong to the organization',
      'user does not have permission to perform this action',
      'file size is large than maximum allowed limit',
      'integration not found',
      'integration does not exist',
      'integration is not connected',
      'integration not found or not connected',
      'template with same name and language already exists',
      'bot has common triggers with other bots',
      'cannot change type of custom field from text to date',
      'quick reply already exists with same shortcut',
      `is already present with name: 'name'. kindly use a different name.`,
      'tag already exists',
      'report expired'
    ];
    const hasIgnorableError = (
      ignorableErrors.findIndex((ignorableError) => error.error?.toLowerCase?.()?.includes?.(ignorableError)) > -1 ||
      ignorableErrors.findIndex((ignorableError) => error.message?.toLowerCase?.()?.includes?.(ignorableError)) > -1
    );
    if (!ignoreSentry && !hasIgnorableError) {
      captureError(error);
    }
    throw new APIError(errorResult);
  }

  return parseResponseToJson<T>(response);
}

export async function get<T>(
  url: string,
  options?: RequestInit & { useDefaultHeaders?: boolean },
  isAuthRequired?: boolean,
  requestUrlType: RequestURLType = RequestURLType.CRM_MINI,
  hasFullUrl?: boolean,
  ignoreSentry?: boolean
) {
  return makeRequest<T>(
    url,
    {
      ...options,
      method: Methods.GET,
    },
    isAuthRequired,
    requestUrlType,
    true,
    hasFullUrl,
    ignoreSentry
  );
}

export async function post<T>(
  url: string,
  options?: RequestInit & { useDefaultHeaders?: boolean },
  isAuthRequired?: boolean,
  requestUrlType: RequestURLType = RequestURLType.CRM_MINI,
  ignoreSentry?: boolean
) {
  return makeRequest<T>(
    url,
    {
      ...options,
      method: Methods.POST,
    },
    isAuthRequired,
    requestUrlType,
    undefined,
    undefined,
    ignoreSentry
  );
}

export async function put<T>(
  url: string,
  options?: RequestInit & { useDefaultHeaders?: boolean },
  isAuthRequired?: boolean,
  requestUrlType: RequestURLType = RequestURLType.CRM_MINI,
  ignoreSentry?: boolean
) {
  return makeRequest<T>(
    url,
    {
      ...options,
      method: Methods.PUT,
    },
    isAuthRequired,
    requestUrlType,
    undefined,
    undefined,
    ignoreSentry
  );
}

export async function patch<T>(
  url: string,
  options?: RequestInit & { useDefaultHeaders?: boolean },
  isAuthRequired?: boolean,
  requestUrlType: RequestURLType = RequestURLType.CRM_MINI,
  ignoreSentry?: boolean
) {
  return makeRequest<T>(
    url,
    {
      ...options,
      method: Methods.PATCH,
    },
    isAuthRequired,
    requestUrlType,
    undefined,
    undefined,
    ignoreSentry
  );
}

export async function deleteReq<T>(
  url: string,
  options?: RequestInit & { useDefaultHeaders?: boolean },
  isAuthRequired?: boolean,
  requestUrlType: RequestURLType = RequestURLType.CRM_MINI,
  ignoreSentry?: boolean
) {
  return makeRequest<T>(
    url,
    {
      ...options,
      method: Methods.DELETE,
    },
    isAuthRequired,
    requestUrlType,
    undefined,
    undefined,
    ignoreSentry
  );
}
