/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';
import { FC } from 'react';
import { getI18N } from '../../../../i18N';
import platform from '../../../../infra/platform';
import { TEXT_COLOR_LIGHT } from '../../../typings';
import { isDesktop } from '../../../utils/platform';
import QSButton from '../../atoms/button';
import QSTypography from '../../atoms/typography';
import { HeaderPrimaryButton } from '../../organisms/header';

interface ErrorContainerProps {
  error?: string;
  customCss?: SerializedStyles;
  showBack?: boolean;
  errorTitleCustomCss?: SerializedStyles;
  onButtonClick?: () => void;
  buttonText?: string;
}

const ErrorContainer: FC<ErrorContainerProps> = ({ error, customCss, showBack = true,errorTitleCustomCss,onButtonClick,buttonText }) => {
  const { t } = getI18N();
  return (
    <div
      css={css(
        css`
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          row-gap: 12px;
        `,
        customCss
      )}
    >
      {!isDesktop() && (
        <HeaderPrimaryButton
          boxProps={{
            sx: {
              position: 'absolute',
              top: 24,
              left: 24,
            },
          }}
          showBack={showBack}
          onClick={platform.finishActivity}
        />
      )}
      <div
        css={css`
          font-family: 'Avenir Next';
          font-size: 16px;
          font-weight: 400;
          line-height: 1.3;
          text-align: center;
          color: ${TEXT_COLOR_LIGHT};
        `}
      >
        <QSTypography
          css={[css`
            font-size: 74px;
            font-weight: 700;
            word-wrap: break-word;
            word-break: break-word;
          `,errorTitleCustomCss]}
        >
          {t('error_messsage')}
        </QSTypography>
        <QSTypography
          css={css`
            font-size: 18px;
            font-weight: 500;
            word-wrap: break-word;
            word-break: break-word;
          `}
        >
          {error ?? t('an_error_occured')}
        </QSTypography>
      </div>
      <QSButton
        variant="text"
        onClick={onButtonClick ? onButtonClick : () => window.location.reload()}
        css={css`
          background: none;
          box-shadow: none;
          outline: none;
        `}
      >
        <QSTypography
          css={css`
            font-size: 16px;
            font-weight: 400;
            word-wrap: break-word;
            word-break: break-word;
          `}
        >
          {buttonText ? buttonText : t('reload_page')}
        </QSTypography>
      </QSButton>
    </div>
  );
};

export default ErrorContainer;
