type RequestConfig = {
  baseUrl: string;
  botBuilderBaseUrl: string;
  botBuilderHost: string;
  qsS3Domain: string;
  qsCloudFrontDomain: string;
  socketUrl: string;
  qsApiUrl: string;
  waPartnerId: string;
  botAppId: string;
};

let requestConfig: RequestConfig;
if (
  process.env.REACT_APP_ENV === 'production' ||
  process.env.REACT_APP_ENV === 'production-2'
) {
  requestConfig = {
    baseUrl: 'https://api.doubletick.io',
    botBuilderBaseUrl: 'https://flow-api.doubletick.io',
    botBuilderHost: 'https://flow.doubletick.io',
    qsS3Domain: 'https://s3.amazonaws.com/quicksales',
    qsApiUrl: 'https://api.quicksell.co',
    qsCloudFrontDomain: 'https://cdn.quicksell.co',
    socketUrl: 'https://socket.doubletick.io',
    waPartnerId: 'ickmk2PA',
    botAppId: '96f06110-3369-4d80-8762-c68d8ec18073',
  };
} else if (process.env.REACT_APP_ENV === 'apiStaging') {
  requestConfig = {
    baseUrl: 'https://api.doubletick.io',
    botBuilderBaseUrl: 'https://flow-api.doubletick.io',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    qsS3Domain: 'https://s3.amazonaws.com/quicksales',
    qsApiUrl: 'https://api.quicksell.co',
    qsCloudFrontDomain: 'https://cdn.quicksell.co',
    socketUrl: 'https://socket.doubletick.io',
    waPartnerId: 'ickmk2PA',
    botAppId: '96f06110-3369-4d80-8762-c68d8ec18073',
  };
} else if (process.env.REACT_APP_ENV === 'apiStaging2') {
  requestConfig = {
    baseUrl: 'https://api-staging-2.doubletick.io',
    botBuilderBaseUrl: 'https://flow-api.doubletick.io',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    qsS3Domain: 'https://s3.amazonaws.com/quicksales',
    qsApiUrl: 'https://api.quicksell.co',
    qsCloudFrontDomain: 'https://cdn.quicksell.co',
    socketUrl: 'https://socket.doubletick.io ',
    waPartnerId: 'ickmk2PA',
    botAppId: '96f06110-3369-4d80-8762-c68d8ec18073',
  };
} else if (process.env.REACT_APP_ENV === 'apiStaging3') {
  requestConfig = {
    baseUrl: 'https://api-staging-3.doubletick.io',
    botBuilderBaseUrl: 'https://flow-api.doubletick.io',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    qsS3Domain: 'https://s3.amazonaws.com/quicksales',
    qsApiUrl: 'https://api.quicksell.co',
    qsCloudFrontDomain: 'https://cdn.quicksell.co',
    socketUrl: 'https://socket.doubletick.io',
    waPartnerId: 'ickmk2PA',
    botAppId: '96f06110-3369-4d80-8762-c68d8ec18073',
  };
} else if (
  process.env.REACT_APP_ENV === 'stagingWithProd' ||
  process.env.REACT_APP_ENV === 'staging2WithProd'
) {
  requestConfig = {
    baseUrl: 'https://api-staging-2.doubletick.io',
    botBuilderBaseUrl: 'https://flow-api.doubletick.io',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    qsS3Domain: 'https://s3.amazonaws.com/quicksales',
    qsApiUrl: 'https://api.quicksell.co',
    qsCloudFrontDomain: 'https://cdn.quicksell.co',
    socketUrl: 'https://socket.doubletick.io',
    waPartnerId: 'ickmk2PA',
    botAppId: '96f06110-3369-4d80-8762-c68d8ec18073',
  };
} else {
  requestConfig = {
    baseUrl: 'https://crm-4.dev.quicksell.co',
    botBuilderBaseUrl: 'https://builder.dev.quicksell.co',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    qsS3Domain: 'https://s3.amazonaws.com/quicksales',
    qsApiUrl: 'https://api.quicksell.co',
    qsCloudFrontDomain: 'https://cdn.quicksell.co',
    socketUrl: 'https://crm-socket.dev.quicksell.co',
    waPartnerId: 'ickmk2PA',
    botAppId: '96f06110-3369-4d80-8762-c68d8ec18073',
  };
}

export default requestConfig;
