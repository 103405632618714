import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loader from '../../../../../shared/components/molecules/loader';
import { DEFAULT_TEAM, getLoadingText } from '../../../../../shared/constants';

const TeamManagementLazyComponentV1 = lazy(
  () => import('../../../../team-management/v1/components')
);

const TeamManagementLazyComponent = lazy(
  () => import('../../../../team-management/v2/components')
);

const TeamManagementInvitesLazyComponent = lazy(
  () =>
    import('../../../../team-management/v1/components/team-member-invites-list')
);

const TemplatesLazyComponent = lazy(
  () => import('../../../../templates/v1/components')
);

const CommerceV2LazyComponent = lazy(
  () => import('../../../../commerce/v2/components')
);

const ChannelManagementLazyComponent = lazy(
  () => import('../../../../channel-management/v1/components')
);

const UserProfileLazyComponent = lazy(
  () => import('../../../../user-profile/v1/components')
);

const BusinessProfileLazyComponent = lazy(
  () => import('../../../../business-profile/v1/components')
);

const BotsLazyComponent = lazy(() => import('../../../../bots/v1/components/'));

const ReportsLazyComponent = lazy(
  () => import('../../../../reports/v1/components')
);

const TagManagementLazyComponent = lazy(
  () => import('../../../../tag-management/v1/components')
);

const SLALazyComponent = lazy(() => import('../../../../sla/v1/components'));

const CustomFieldsLazyComponent = lazy(
  () => import('../../../../custom-fields/v1/components')
);

const WebhooksLazyComponent = lazy(
  () => import('../../../../webhooks/v1/components')
);

const WebhooksV2LazyComponent = lazy(
  () => import('../../../../webhooks/v2/components')
);

const CustomersLazyComponent = lazy(
  () => import('../../../../customers/v1/components')
);

const AnalyticsLazyComponent = lazy(
  () => import('../../../../analytics/v1/components')
);

const DeveloperApiManagementLazyComponent = lazy(
  () => import('../../../../developer-api/v1/components')
);

const ScheduledBroadcastsLazyComponent = lazy(
  () => import('../../../../scheduled-broadcasts/v1/components')
);

const BillingLazyComponent = lazy(
  () => import('../../../../billing/v1/components')
);

const WhatsAppWidgetsLazyComponent = lazy(
  () => import('../../../../whatsapp-widgets/v1/components')
);

const QuickReplyLazyComponent = lazy(
  () => import('../../../../quick-replies/v1/components')
);

const ChatGptLazyComponent = lazy(
  () => import('../../../../chat-gpt/v1/components')
);

const AIBotLazyComponent = lazy(
  () => import('../../../../ai-bot/v1/components')
);

const SendTemplateMessagesLazyComponent = lazy(
  () => import('../../../../send-template-messages/v1/components')
);

const WabaChannelsLazyComponent = lazy(
  () => import('../../../../waba-channels/v1/components')
);

const RolesListLazyComponent = lazy(
  () => import('../../../../roles/v1/components/roles-list')
);

const PermissionsListLazyComponent = lazy(
  () => import('../../../../roles/v1/components/permissions-list')
);

const ChangeLoginNumberLazyComponent = lazy(
  () => import('../../../../user-profile/v1/components/change-login-number')
);
const PlansLazyComponent = lazy(() => import('../../../../plans/components'));

const WorkingHoursLazyComponent = lazy(
  () => import('../../../../working-hours/components')
);

const DashboardLazyComponent = lazy(
  () => import('../../../../dashboard/components')
);

const OnboardingLazyComponent = lazy(
  () => import('../../../../onboarding/components')
);


export default function getRoutesV1() {
  const loadingText = getLoadingText();
  const loader = <Loader size={32} secondary={loadingText} />;

  return [
    <Route
      key="/v1/team-management"
      path="/v1/team-management"
      element={
        <Suspense fallback={loader}>
          <TeamManagementLazyComponentV1 />
        </Suspense>
      }
    />,
    <Route
      key="/v1/team-management/members"
      path="/v1/team-management/members"
      element={
        <Suspense fallback={loader}>
          <TeamManagementLazyComponentV1 />
        </Suspense>
      }
    />,
    <Route
      key="/v1/team-management/invites/:status"
      path="/v1/team-management/invites/:status"
      element={
        <Suspense fallback={loader}>
          <TeamManagementInvitesLazyComponent teamId={DEFAULT_TEAM} />
        </Suspense>
      }
    />,
    <Route
      key="/v2/team-management"
      path="/v2/team-management"
      element={
        <Suspense fallback={loader}>
          <TeamManagementLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/channel-management/*"
      path="/v1/channel-management/*"
      element={
        <Suspense fallback={loader}>
          <ChannelManagementLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v2/commerce/*"
      path="/v2/commerce/*"
      element={
        <Suspense fallback={loader}>
          <CommerceV2LazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/templates/*"
      path="/v1/templates/*"
      element={
        <Suspense fallback={loader}>
          <TemplatesLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/business-profile"
      path="/v1/business-profile"
      element={
        <Suspense fallback={loader}>
          <BusinessProfileLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/bots/*"
      path="/v1/bots/*"
      element={
        <Suspense fallback={loader}>
          <BotsLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/custom-fields/*"
      path="/v1/custom-fields/*"
      element={
        <Suspense fallback={loader}>
          <CustomFieldsLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/webhooks/*"
      path="/v1/webhooks/*"
      element={
        <Suspense fallback={loader}>
          <WebhooksLazyComponent />
        </Suspense>
      }
    />,
    <Route
    key="/v2/webhooks/*"
    path="/v2/webhooks/*"
    element={
      <Suspense fallback={loader}>
        <WebhooksV2LazyComponent />
      </Suspense>
    }
  />,
    <Route
      key="/v1/customers"
      path="/v1/customers"
      element={
        <Suspense fallback={loader}>
          <CustomersLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/analytics"
      path="/v1/analytics"
      element={
        <Suspense fallback={loader}>
          <AnalyticsLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/developer-api"
      path="/v1/developer-api"
      element={
        <Suspense fallback={loader}>
          <DeveloperApiManagementLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/scheduled-broadcasts"
      path="/v1/scheduled-broadcasts"
      element={
        <Suspense fallback={loader}>
          <ScheduledBroadcastsLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/billing/*"
      path="/v1/billing/*"
      element={
        <Suspense fallback={loader}>
          <BillingLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/widgets/*"
      path="/v1/widgets/*"
      element={
        <Suspense fallback={loader}>
          <WhatsAppWidgetsLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/quick-reply/*"
      path="/v1/quick-reply/*"
      element={
        <Suspense fallback={loader}>
          <QuickReplyLazyComponent />
        </Suspense>
      }
    />,
    <Route
    key="/v1/tag-management/*"
    path="/v1/tag-management/*"
    element={
      <Suspense fallback={<Loader size={32} secondary={getLoadingText()} />}>
        <TagManagementLazyComponent />
      </Suspense>
    }
  />,
    <Route
      key="/v1/chat-gpt/*"
      path="/v1/chat-gpt/*"
      element={
        <Suspense fallback={<Loader size={32} secondary={getLoadingText()} />}>
          <ChatGptLazyComponent />
        </Suspense>
      }
    />,

    <Route
      key="/v1/plans/*"
      path="/v1/plans/*"
      element={
        <Suspense fallback={<Loader size={32} secondary={getLoadingText()} />}>
          <PlansLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/send-template-messages/*"
      path="/v1/send-template-messages/*"
      element={
        <Suspense fallback={<Loader size={32} secondary={getLoadingText()} />}>
          <SendTemplateMessagesLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/reports/*"
      path="/v1/reports/*"
      element={
        <Suspense fallback={<Loader size={32} secondary={getLoadingText()} />}>
          <ReportsLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/sla/*"
      path="/v1/sla/*"
      element={
        <Suspense fallback={<Loader size={32} secondary={getLoadingText()} />}>
          <SLALazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/ai-bot/*"
      path="/v1/ai-bot/*"
      element={
        <Suspense fallback={loader}>
          <AIBotLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/waba-channels/*"
      path="/v1/waba-channels/*"
      element={
        <Suspense fallback={loader}>
          <WabaChannelsLazyComponent excludePrimaryField={true} />
        </Suspense>
      }
    />,
    <Route
      key="/v1/roles"
      path="/v1/roles"
      element={
        <Suspense fallback={loader}>
          <RolesListLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/roles/new/:roleType"
      path="/v1/roles/new/:roleType"
      element={
        <Suspense fallback={loader}>
          <PermissionsListLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/roles/:roleId/:roleType"
      path="/v1/roles/:roleId/:roleType"
      element={
        <Suspense fallback={loader}>
          <PermissionsListLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/v1/profile/*"
      path="/v1/profile/*"
      element={
        <Routes>
          <Route
            key="/change-number"
            path="/change-number"
            element={
              <Suspense fallback={loader}>
                <ChangeLoginNumberLazyComponent />
              </Suspense>
            }
          />
          <Route
            key="/"
            path="/"
            element={
              <Suspense fallback={loader}>
                <UserProfileLazyComponent />
              </Suspense>
            }
          />
        </Routes>
      }
    />,
    <Route
      key="/v1/working-hours"
      path="/v1/working-hours"
      element={
        <Suspense fallback={loader}>
          <WorkingHoursLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/dashboard"
      path="/dashboard"
      element={
        <Suspense fallback={<Loader size={32} secondary={getLoadingText()} />}>
          <DashboardLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key="/onboarding"
      path="/onboarding"
      element={
        <Suspense fallback={<Loader size={32} secondary={getLoadingText()} />}>
          <OnboardingLazyComponent />
        </Suspense>
      }
    />,
  ];
}
